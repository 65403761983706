import { get, store} from "@/services/resources";

export const  state = {
    conventionStats:undefined,

};
export const mutations = {
    SET_CONVENTION_STATS(state,payload){
        state.conventionStats = {...payload}
    },


};
export const actions = {
    get({commit}){
        return new Promise((resolve,reject)=>{
            get("").then((response)=>{
                commit('SET_STAFF',response.data)
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },

    getConventionStats(_, data){
        return new Promise((resolve,reject)=>{
            store("stats/conventions", data).then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    getStats(_, data){
        return new Promise((resolve,reject)=>{
            store(data.url, data).then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },


};


