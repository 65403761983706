import { DASHBOARD} from "@/services/apis";
import client from "@/state/axios/client";

export const getCensus = (params) => {
    return new Promise((resolve,reject)=>{
        client({
            method : 'GET',
            url:'censuses?'+params,
        }).then(response => resolve(response))
            .catch((error)=>reject(error.response))
    })
}
export const getDuplicates = (params) => {
    return new Promise((resolve,reject)=>{
        client({
            method : 'GET',
            url:'duplicates?'+params,
        }).then(response => resolve(response))
            .catch((error)=>reject(error.response))
    })
}
export const get = (data) => {
    return new Promise((resolve,reject)=>{
        let params = data.params === undefined ?  `?page=${data.page}&per_page=${data.per_page}&paginate=${data.paginate || ''}&search=${data.search}` : data.params;
        client({
            method : 'GET',
            url: data.resource + params,
        }).then(response => resolve(response))
            .catch((error)=>reject(error.response))
    })
}
export const getWithoutParams = (url) => {
    return new Promise((resolve,reject)=>{
        client({
            method : 'GET',
            url: url,
        }).then(response => resolve(response))
            .catch((error)=>reject(error.response))
    })
}
export const create = (module, query)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'GET',
            url: module + `/create${query}`,
        }).then(response => resolve(response))
            .catch((error)=>reject(error.response))
    })
}
export const show = (module,id)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'GET',
            url:module+'/'+id,
        }).then(response => resolve(response))
            .catch((error)=>reject(error.response))
    })
}
export const edit = (module,modelId)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'GET',
            url:module+`/${modelId}/edit`,
        }).then(response => resolve(response))
            .catch((error)=>reject(error.response))
    })
}
export const store = (module,data)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'POST',
            url:module,
            data:data
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}
export const generate = (module,data)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'POST',
            url:module,
            responseType: 'blob',
            data:data
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}
export const download = (module)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'GET',
            url:module,
            responseType: 'blob',
        }).then(response => resolve(response))
            .catch((error) => reject(error))
    })
}
export const changeStatus = (data)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'POST',
            url:"v1/orders/change/status",
            data:data
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}
export const dashboard = (data)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'POST',
            url:DASHBOARD,
            data:data
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}
export const updateFormData = (module,data)=>{
    let requestData = data.id?data.data:data;
    return new Promise((resolve,reject)=>{
        client({
            method : 'POST',
            url:module+'/'+data.id,
            data:requestData
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}
export const update = (module,data,id)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'POST',
            url:module+"/"+id,
            data:data,
        }).then(response => resolve(response))
            .catch(error=>reject(error))
    })
}
export const disableUser = (userId)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'PUT',
            url:"users/disable/"+userId,
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}
export const destroy = (module,modelId)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'DELETE',
            url:module+'/'+modelId,
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}
